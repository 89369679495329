const basic_modificacion = {
  ingresos: {
    remuneracionAnualCargoPublico: {
      valor: null,
      moneda: null
    },
    otrosIngresosAnualesTotal: {
      valor: null,
      moneda: null
    },
    actividadFinanciera: {
      remuneracionTotal: {
        valor: null,
        moneda: null
      },
      actividades: []
    },
    serviciosProfesionales: {
      remuneracionTotal: {
        valor: null,
        moneda: null
      },
      servicios: []
    },
    enajenacionBienes: {
      remuneracionTotal: {
        valor: null,
        moneda: null
      },
      bienes: []
    },
    otrosIngresos: {
      remuneracionTotal: {
        valor: null,
        moneda: null
      },
      ingresos: []
    },
    ingresoAnualNetoDeclarante: {
      valor: null,
      moneda: null
    },
    ingresoAnualNetoParejaDependiente: {
      valor: null,
      moneda: null
    },
    totalIngresosAnualesNetos: {
      valor: null,
      moneda: null
    },
    aclaracionesObservaciones: null
  }
};

export default basic_modificacion;
